"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.InventoryItem = void 0;
const merge_1 = require("@walls/merge");
const Contact_1 = require("../contacts/Contact");
const QuoteRequestProduct_1 = require("../quotes/quoteRequest/QuoteRequestProduct");
const ColourOverride_1 = require("./ColourOverride");
const ContractorInventoryItem_1 = require("./ContractorInventoryItem");
const ProductUnitsOfMeasure_1 = require("./ProductUnitsOfMeasure");
const ProductUpcItems_1 = require("./ProductUpcItems");
const TechnicalFormulas_1 = require("./TechnicalFormulas");
/**
 * The main Product details for a inventory item
 */
class InventoryItem {
    constructor(data) {
        /**
         * The main product identifier
         */
        this.product_id = "";
        /**
         * When we add upc scanners then store the code here
         */
        this.upcCode = "";
        /**
         * The list of universal product codes
         *
         * aka barcode
         */
        this.upcList = [];
        /**
         * The item category
         *
         * @example `trims | boots | panel`
         */
        this.category = "";
        /**
         * The publicly known name for the item
         */
        this.name = "";
        /**
         * A descriptive text for the item. This will be used on the website to describe the item.
         *
         * This is also used when printing quotes for the panels selected
         */
        this.description = "";
        /**
         * The default purchase description to use for the product
         *
         * If this value is not set... fallback to product name
         */
        this.purchaseDescription = "";
        /**
         * Tell if the item is available online quote request
         */
        this.availableOnline = true;
        /**
         * Tell if the item is available for contractors
         */
        this.availableContractors = true;
        /**
         * Tells if the product can be selected from a quote
         */
        this.canBeSold = true;
        /**
         * The supplier id where we normally purchase the item from
         *
         * @deprecated
         */
        this.supplier = "";
        /**
         * If this is a child item then set the parent id
         */
        this.parentId = null;
        /**
         * If the parent id is set then also set the parent name here
         */
        this.parentName = "";
        /**
         * List of items that are a child of this... This is the legacy way used when converting from one price to another
         */
        this.childItems = [];
        /**
         * A key-value pairing for the material to product relation ships.
         *
         * Use this mapping to check which product to map to.
         *
         * The key is the priceCategory id, and the value is the product id.
         */
        this.materialMapping = null;
        /**
         * The list of potential parent ids for the given product
         */
        this.materialParents = [];
        /**
         * The price category this item is part of
         */
        this.priceCategory = "";
        /**
         * Tells if the item in question is a panel or regular item.
         */
        this.isPanel = false;
        /**
         * Tells if the current product is a trim.
         */
        this.isTrim = false;
        /**
         * Tells if the product is labeled as a coil
         *
         * For backwards compatibility, isTrim will also be true if this is true
         */
        this.isCoil = false;
        /**
         * The panel width.
         *
         * This value is used to calculate quantities between different panel types
         */
        this.panelWidth = 0;
        /**
         * The total amount of inches of material used...
         *
         * Setting this value triggers the auto calculate for weight and our cost.
         * These numbers get loaded from the Material on use
         */
        this.materialsUsed = 0;
        this.consumption = {
            active: false,
            method: "qty",
            matchColour: false,
            consumes: [],
            substituteProduct: {}, // this value should not be set from the InventoryItem.
        };
        /**
         * The list of units of measure available for the product
         */
        this.unitsOfMeasure = {
            primaryUnit: "qty",
            purchaseUnit: null,
            list: [],
        };
        /**
         * Provides the given technical information for this item
         */
        this.technical = null;
        /**
         * The list of colour overrides for the product
         */
        this.colourOverrides = [];
        /**
         * Not stored in database.
         * This should be fetched when the product details are loaded
         *
         * @info Start using the getAvailableColours method to get the list of available colours instead
         */
        this.availableColours = [];
        /**
         * The list of materials ids to select the colours from.
         *
         * If empty... then it defaults to only the product material
         */
        this.coloursFromMaterials = [];
        /**
         * Tells if the product weight should be calculated
         */
        this.calculateWeight = false;
        /**
         * The weight of the product.
         *
         * If this is a panel then the weight of a linear foot
         */
        this.weight = 0;
        /**
         * The price that we pay in CAD
         */
        this.buyingPrice = 0;
        /**
         * Exchange rate from USD to CAD
         */
        this.exchangeRate = 0;
        /**
         * The price we pay in USD
         */
        this.buyingPriceUSD = 0;
        /**
         * Our markup percentage
         */
        this.markupPercentage = 0;
        /**
         * The true value that the price would be if the values were not rounded off
         */
        this.calculatedPrice = 0;
        /**
         * Our retail selling price of the item
         */
        this.sellingPrice = 0;
        /**
         * A list of different discounted prices based on discount level
         */
        this.discountedPrices = [];
        /**
         * Tell if it should track uses of the item
         */
        this.trackQuantityOnHand = true;
        /**
         * Tells if LOT numbers should be tracked for this product.
         */
        this.trackLotDetails = false;
        /**
         * Our current qty on hand...
         *
         * This value might not be accurate and should be updated daily
         *
         * This value should be pulled from items_qty_current table while fetching the item
         */
        this.quantityOnHand = 0;
        /**
         * The minimum quantity that we want on hand
         */
        this.minQuantityWanted = 0;
        /**
         * The amount of time in advance to notify that stock is running low.
         *
         * This value defaults to 2 weeks
         */
        this.reOrderTime = 24 * 60 * 60 * 14;
        /**
         * A list of images for the product
         */
        this.productImages = [];
        /**
         * The svg file name for this product.
         * The svg file is a 2d vector of the product as a visual.
         * This will be printed on quotes for panels
         */
        this.productSVG = "";
        /**
         * The url for the obj file that might be stored for this item as a visual
         */
        this.productOBJ = "";
        /**
         * The date that the item was created
         */
        this.dateCreated = Math.floor(Date.now() / 1000);
        /**
         * Add product constraints
         *
         * Ignore the value if set to 0
         */
        this.constraints = {
            quantity: { min: 0, max: 0 },
            sheetLength: { min: 0, max: 0 },
        };
        /**
         * Time of last edit
         */
        this.lastedit = 0;
        /**
         * Tells if the item is deleted or not
         */
        this.deleted = false;
        /**
         * Indicates whether sensitive information has been removed from the inventory item.
         *
         * This needs to be set to true when the item is being sent a internal none admin client
         */
        this.sensitiveInfoRemoved = false;
        if (data) {
            this.update(data);
        }
    }
    /**
     * Update the current inventory Item data
     *
     * @param data
     */
    update(data) {
        // update the technical details
        if (data.technical) {
            this.technical = (0, TechnicalFormulas_1.setupProductTechnical)(data.technical);
            delete data.technical;
        }
        // update the units of measure details
        if (data.unitsOfMeasure) {
            if (data.unitsOfMeasure.list) {
                // create the list of units for the product
                this.unitsOfMeasure.list = data.unitsOfMeasure.list.map(unit => (0, ProductUnitsOfMeasure_1.createProductUnitsListItem)(unit));
                delete data.unitsOfMeasure.list;
            }
            // assign the units details
            Object.assign(this.unitsOfMeasure, data.unitsOfMeasure);
            delete data.unitsOfMeasure;
        }
        if (data.colourOverrides) {
            this.colourOverrides = data.colourOverrides
                .map(data => (0, ColourOverride_1.createColourOverride)(data))
                .filter(val => Boolean(val.colour));
            delete data.colourOverrides;
        }
        if (data.materialParents) {
            this.materialParents = data.materialParents.map(data => ({
                productId: "",
                name: "",
                ...data,
            }));
            delete data.materialParents;
        }
        // setup the list of product bar codes
        if (data.upcList) {
            this.upcList = data.upcList.map(d => (0, ProductUpcItems_1.setupProductUpcItems)(d));
            delete data.upcList;
        }
        // TODO: Setup tests to try setting some of the required values. The rest should auto fill
        // merge the incoming object into this class
        (0, merge_1.assign)(this, data);
    }
    /**
     * Create a clone of this item and return it
     */
    clone() {
        const data = (0, merge_1.clone)(this, {});
        return new InventoryItem(data);
    }
    /**
     * Get the price for the given discountCategory
     *
     * @param discountCategory
     * @param colour optionally set the colour to get the values for
     */
    getPrice(discountCategory, colour) {
        if (discountCategory === "") {
            discountCategory = "default";
        }
        // get the colour value
        const colourOverride = colour && this.consumption.matchColour
            ? this.getColourOverride(colour)
            : null;
        // get the cost price value
        const costPrice = colourOverride && colourOverride.pricing.cost
            ? colourOverride.pricing.cost
            : this.buyingPrice;
        if (discountCategory !== "default") {
            for (const price of this.discountedPrices) {
                if (price.discountId === discountCategory) {
                    return {
                        name: price.name,
                        calculatedPrice: price.calculatedPrice,
                        sellingPrice: price.sellingPrice,
                        buyingPrice: costPrice,
                    };
                }
            }
        }
        return {
            name: "default",
            calculatedPrice: this.calculatedPrice,
            sellingPrice: this.sellingPrice,
            buyingPrice: costPrice,
        };
    }
    /**
     * Get the product type
     *
     * @returns
     */
    getProductType() {
        if (this.isCoil) {
            return "coil";
        }
        if (this.isPanel) {
            return "panel";
        }
        if (this.isTrim) {
            return "trim";
        }
        return "generic";
    }
    /**
     * Create a contractor item from the current item
     *
     * @param contact
     */
    createContractorItem(contact) {
        if (!(contact instanceof Contact_1.ContactModel)) {
            throw new TypeError("Failed to setup the Product details specific to the contact.");
        }
        // create a new ContractorInventoryItem
        const item = new ContractorInventoryItem_1.ContractorInventoryItem();
        // copy each value over that exists on both sides
        for (let key of Object.keys(item)) {
            if (typeof this[key] !== "undefined") {
                item[key] = this[key];
            }
        }
        // set the contractor price
        const preferredPrice = contact.getPreferredPricing(this.product_id);
        if (preferredPrice) {
            // if a preferred price is set for the contact, then use that
            item.contractorPrice = preferredPrice.sellingPrice;
        }
        else {
            // otherwise use the default price for the price list set in the contact
            item.contractorPrice = this.getPrice(contact.settings.discountId).sellingPrice;
        }
        return item;
    }
    /**
     * Create the QuoteRequestProduct details
     *
     * @returns
     */
    createQuoteRequestProduct() {
        const item = this.clone();
        const data = {
            productId: item.product_id,
            parentId: item.parentId,
            displayName: item.name,
            description: item.description,
            constraints: item.constraints,
            panelWidth: item.panelWidth,
            technical: item.technical,
            // setup the material details
            material: {
                materialId: item.priceCategory,
                materialName: "",
            },
            materialMapping: item.materialMapping,
            materialParents: item.materialParents,
            pricing: {
                isTaxable: true,
                unitPrice: item.sellingPrice,
            },
            productType: this.getProductType(),
            productIcon: item.productSVG,
            productImages: item.productImages,
            imagesByColour: item.colourOverrides
                .filter(override => !override.deleted)
                .map(override => ({
                colour: override.colour,
                productImages: override.productImages,
            })),
        };
        // setup the QuoteRequest Product details
        return new QuoteRequestProduct_1.QuoteRequestProductModel(data);
    }
    /**
     * Add the given discounted price to the inventory item
     *
     * @param price
     */
    addDiscountPrice(price) {
        const category = {
            active: true,
            discountId: "",
            discountPercent: 0,
            name: "",
            parent: "",
            calculatedPrice: 0,
            sellingPrice: 0,
        };
        (0, merge_1.assign)(category, price);
        if (typeof price.sellingPrice === "undefined" ||
            price.sellingPrice === 0) {
            // get the parent price to set as default to the new category
            const priceGroup = this.getPrice(price.parent ? price.parent : "default");
            category.calculatedPrice = priceGroup.calculatedPrice;
            category.sellingPrice = priceGroup.sellingPrice;
        }
        this.discountedPrices.push(category);
    }
    /**
     * Get the given formula value from the product
     *
     * @param name the name to match to
     */
    getFormula(name) {
        if (this.technical && this.technical.formulas.length > 0) {
            if (name) {
                // match the formula name
                for (const value of this.technical.formulas) {
                    if (value.name === name) {
                        return value;
                    }
                }
            }
            // get the default formula
            for (const value of this.technical.formulas) {
                if (value.default) {
                    return value;
                }
            }
            // return the first formula value as final resort
            return this.technical.formulas[0];
        }
        return null;
    }
    /**
     * Get the colour override values.
     *
     * This will also load the downstream colour override if the useColour value is set
     *
     * @param colour
     * @param createWhenMissing if true, will create and add the colour override if missing
     */
    getColourOverride(colour, createWhenMissing) {
        const colourIndex = this.colourOverrides.findIndex(val => val.colour === colour);
        const value = this.colourOverrides[colourIndex];
        // get the colour override value
        if (value && !value.deleted) {
            if (value.useColour) {
                // load the downstream colour override
                const downstream = this.getColourOverride(value.useColour, createWhenMissing);
                if (downstream) {
                    return downstream;
                }
                // return a default value
                return (0, ColourOverride_1.createColourOverride)({ colour: value.useColour });
            }
            return value;
        }
        // create the colour override when missing
        if (createWhenMissing && this.availableColours.includes(colour)) {
            const override = (0, ColourOverride_1.createColourOverride)({ colour });
            if (value) {
                // replace the current value
                this.colourOverrides[colourIndex] = override;
            }
            else {
                // add the new value
                this.colourOverrides.push(override);
            }
            return override;
        }
        return null;
    }
    /**
     * Get the list of disabled colours
     *
     * @returns
     */
    getDisabledColours() {
        return this.colourOverrides
            .filter(val => val.useColour)
            .map(val => val.colour);
    }
    /**
     * Get the list of available colours
     *
     * @returns
     */
    getAvailableColours() {
        const disabled = this.getDisabledColours();
        return this.availableColours.filter(colour => !disabled.includes(colour));
    }
    /**
     * Get the weight for the given colour
     *
     * @param colour
     */
    getProductWeight(colour) {
        const colourOverride = this.getColourOverride(colour);
        if (colourOverride && colourOverride.weight) {
            return colourOverride.weight;
        }
        // default to returning the product weight
        return this.weight;
    }
    /**
     * Get the list of available units of measure
     *
     * @param defaultAll when true will return the default list of units if none are set
     */
    getAvailableUnitsOfMeasure(defaultAll) {
        const list = this.unitsOfMeasure.list.filter(unit => !unit.archived);
        // return the list of units
        if (list.length > 0) {
            return list.map(unit => unit.unit);
        }
        if (defaultAll) {
            return InventoryItem.getUnitsOfMeasureList();
        }
        return [];
    }
    /**
     * Get the unit multiplier for the given unit of measure name
     *
     * @param uom
     * @returns
     */
    getUnitOfMeasureMultiplier(uom) {
        const unit = this.unitsOfMeasure.list.find(u => u.unit === uom);
        // get and return the unit multiplier
        if (unit) {
            return unit.multiplier;
        }
        // default return 1
        return 1;
    }
    /**
     * Remove sensitive information from the inventory item such as cost price
     */
    removeSensitiveInfo() {
        // remove all cost / buying pricing
        this.buyingPrice = 0;
        this.buyingPriceUSD = 0;
        this.markupPercentage = 0;
        this.calculatedPrice = 0;
        this.sensitiveInfoRemoved = true;
    }
    /**
     * Get the list of units of measures available
     *
     * @returns
     */
    static getUnitsOfMeasureList() {
        return Object.keys(ProductUnitsOfMeasure_1.UnitsListMapping);
    }
}
exports.InventoryItem = InventoryItem;
